import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function MetaTags({ channelData }) {
  const ogUrl = process.env.REACT_APP_ROOT_DOMAIN;
  const ogTitle = process.env.REACT_APP_OG_TITLE;
  const ogImage = process.env.REACT_APP_OG_IMAGE;
  const twitterCard = process.env.REACT_APP_TWITTER_CARD;
  const twitterImageSrc = process.env.REACT_APP_TWITTER_IMAGE;
  const ogDescription = process.env.REACT_APP_OG_DESCRIPTION;

  const sortedChannelData = channelData.sort((a, b) => a.order - b.order);
  const pageTitle = sortedChannelData.length > 0 ? `Streams | ${sortedChannelData.map((channel) => channel.username).join(', ')}` : 'Watch multiple Twitch and YouTube streams | All the Streams!';

  return (
    <HelmetProvider>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:image" content={ogImage} />
        <meta property="twitter:card" content={twitterCard} />
        <meta property="twitter:image:src" content={twitterImageSrc} />
        <meta property="og:description" content={ogDescription} />
        <link rel="canonical" href="https://watchstreams.tv" />
      </Helmet>
    </HelmetProvider>
  );
}

export default MetaTags;
